// InsuranceSteps.js
import React from 'react';
import * as FaIcons from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './explore.css';

const stepsData = [
  {
    "icon": "FaUser",
    "name": "Life Insurance",
    "description": "Coverage for an person's life needs",
    "redirect":"/products/individual/life"
  },
  {
    "icon": "FaUserFriends",
    "name": "Family Health Insurance",
    "description": "Coverage for entire family's health needs",
    "redirect":"/products/individual/health/family-plan"
  },
  {
    "icon": "FaMotorcycle",
    "name": "Bike Insurance",
    "description": "Insurance coverage for motorcycles",
    "redirect": "/products/individual/motor"
  },
  {
    "icon": "FaCar",
    "name": "Car Insurance",
    "description": "Insurance coverage for automobiles",
    "redirect": "/products/individual/motor"
  },
  {
    "icon": "FaHome",
    "name": "Home Insurance",
    "description": "Insurance for safeguarding your home",
    "redirect": "/products/individual/property/home-insurance"	
  },
    {
    "icon": "FaUsers",
    "name": "Group Term Insurance",
    "description": "Insurance coverage for groups with terms",
    "redirect": "/products/corporate/group/term"
  },
    {
    "icon": "FaUserTie",
    "name": "Senior Citizen Health Insurance",
    "description": "Health coverage tailored for senior citizens",
    "redirect": "/products/individual/health/Senior-Citizen-Health-Insurance"
  },
  {
    "icon": "FaBriefcase",
    "name": "Employee And Group Welfare",
    "description": "Insurance for employee welfare and groups",
    "redirect": "/products/corporate/group"
  },
    {
    "icon": "FaUser",
    "name": "Individual Health Insurance",
    "description": "Coverage for an individual's health needs",
    "redirect": "/products/individual/health/self-plan"
  },
      {
    "icon": "FaMedkit",
    "name": "Mediclaim Policy",
    "description": "Policy covering medical expenses",
    "redirect": "/products/corporate/group/mediclaim"
  }
]

const InsuranceSteps = () => {
  const navigate = useNavigate()
  return (
    <div className="insurance-steps-container">
      <div className="icon-step-container">
        {stepsData.map((step, index) => (
          <div className="step" key={index}>
            {React.createElement(FaIcons[step.icon], { className: 'icon' })}
            <div className="step-description">{step.name}</div>
            <div className="step-title">{step.description}</div>
            <button onClick={() => navigate(step.redirect)} >LEARN MORE</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsuranceSteps;
