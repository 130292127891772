import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from "react-redux"
import { updateBasic } from "../../../../src/Redux/posRedux"
import { axiosPOSTCall, axiosGETCall, axiosPUTCall, axiosPUTCall1 } from "../../../utilities/utils/apiPost"
import { validation } from '../../../utilities/validations';
import ErrorPopups from './ErrorPopups';
// const [error, setError] = useState({})

const Basic = () => {
  // const [items2,setItems2] =useState()
  const dispatch = useDispatch();
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const posRedux = useSelector((state) => state.posRedux.posData);
  // console.log(" posRedux---> ", posRedux);
  const [addressData, setAddressData] = useState({
    gender: '',
    dob: '',
    email: '',
    pancard: '',
    basicQualification: '',
    mobNo: '',
  });

  useEffect(() => {
    if (!userDetails) {
      navigate('/');
    } else if (userDetails.roles && userDetails.roles[0] !== 'admin') {
      setAddressData((prevState) => ({
        ...prevState,
        gender: posRedux?.gender || '',
        dob: posRedux?.dob || '',
        email: posRedux?.email || userDetails.email,
        pancard: posRedux?.panNo || '',
        basicQualification: posRedux?.qualification || '',
        mobNo: posRedux?.phone || userDetails.phone,
      }));
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(posRedux).length) {
      navigate('/partner');
    } else if (
      !posRedux.gender ||
      !posRedux.email ||
      !posRedux.panNo ||
      !posRedux.qualification ||
      !posRedux.phone
    ) {
      navigate('/details');
    } else if (!posRedux.language?.training || !posRedux.language?.exam) {
      navigate('/lang');
    } else if (!posRedux.segment) {
      navigate('/segment');
    } else if (
      !posRedux.documents?.profile ||
      !posRedux.documents?.aadharFront ||
      !posRedux.documents?.pancard ||
      !posRedux.documents?.education ||
      !posRedux.documents?.signature
    ) {
      navigate('/documents');
    }
  }, [navigate, posRedux]);



  const clickHandle = () => {
    let obj = {
      gender: addressData.gender,
      dob: addressData.dob,
      email: addressData.email,
      panNo: addressData.pancard,
      qualification: addressData.basicQualification,
      phone: addressData.mobNo
    }
    // console.log("🚀 ~ file: Basic.jsx:40 ~ clickHandle ~ obj.dob:", addressData.dob)

    if (!addressData.gender) {
      setError({ gender: 'Please Select Gender' })
    }
    else if (!validation(addressData.dob, "age").status) {
      setError({ dob: validation(addressData.dob, "age").message })
    }
    else if (!validation(addressData.email, 'email').status) {
      setError({ email: 'Please Valid Enter Email' })
    }
    else if (!validation(addressData.pancard, 'pan').status) {
      setError({ pancard: 'Please Enter Valid PAN' })
    }
    else if (!addressData.basicQualification) {
      setError({ basicQualification: 'Please Select Qualification' })
    }
    else if (!validation(addressData.mobNo, 'mob').status) {
      setError({ mobNo: 'Please Enter Mobile No.' })
      // setError({chassisNo: 'Please Enter atleast 15 digits'})
    }
    else {
      axiosPUTCall1('pospInfo/' + posRedux._id, obj, (callBack => {
        if (callBack.status === "Success") {
          dispatch(updateBasic(callBack.data));
          // console.log("callback are bewfnlk. ,", callBack.data);
          navigate("/lang");
        }
      }));

    }


    // setItems2(false)
  }

  // useEffect(() => {
  //   if (Object.keys(posRedux).length <= 0) {
  //     navigate('/partner')
  //   } else if (!posRedux.gender || !posRedux.dob || !posRedux.email || !posRedux.panNo || !posRedux.qualification || !posRedux.phone) {
  //     navigate('/details')
  //   } else if (!posRedux?.language?.training || !posRedux?.language?.exam) {
  //     navigate('/lang')
  //   } else if (!posRedux?.segment) {
  //     navigate('/sengment')
  //   } else if (!posRedux?.documents?.profile || !posRedux?.documents?.aadharFront || !posRedux?.documents?.pancard || !posRedux?.documents?.education || !posRedux?.documents?.signature) {
  //     navigate('/documents')
  //   }
  // }, [])

  const removeError = (key) => {
    switch (key) {
      case 'gender':
        if (error.gender) return setError(error.gender = "")
        break
      case 'dob':
        if (error.dob) return setError(error.dob = "")
        break
      case 'email':
        if (error.email) return setError(error.email = "")
        break
      case 'age':
        if (error.age) return setError(error.age = "")
        break
      case 'pancard':
        if (error.pancard) return setError(error.pancard = "")
        break
      case 'basicQualification':
        if (error.basicQualification) return setError(error.basicQualification = "")
        break
      case 'mobNo':
        if (error.mobNo) return setError(error.mobNo = "")
        break
      default:
        break;
    }
  }

   const cancelHandler = () => {
      const fromAdminDash = localStorage.getItem('addPos')

      if (fromAdminDash) {
        navigate('/dashboard/agent')
        localStorage.removeItem('addPos')
      } else {
      navigate('/work-with-us')
    }
  }
  
  return (
    <>
      {/* {Object.keys(posRedux).length === 0 ? (navigate('/partner')) : ''} */}
      <div className="mainWrapper">

        <div className="containerContent">
        <div className="cancelBtn" onClick={cancelHandler}>&times;</div>

          <Sidebar />
          <div className="pos_input_container">
            <h3>Basic Details :</h3>
            <form action="">
              <div className="input_box_divider">
                {/* <p>full name</p> */}
                <div className="input_box" onClick={() => removeError("gender")}>
                  <p>GENDER&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <select id="gender" name="gender" value={addressData.gender} onChange={(e) => setAddressData(prev => ({ ...prev, gender: e.target.value }))}>
                    <option value={-1}>Select Gender :</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {error.hasOwnProperty('gender') ? (
                    <ErrorPopups error={error.gender} />
                  ) : null}
                </div>
                <div className=' input_box' onClick={() => removeError("email")}>
                  <p>EMAIL ID&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="email"
                    id='house'
                    name='email'
                    value={addressData.email}
                    onChange={(e) => {
                      setAddressData(prev => ({ ...prev, email: e.target.value }))
                    }}
                  />
                  {error.hasOwnProperty('email') ? (
                    <ErrorPopups error={error.email} />
                  ) : null}
                </div>
                <div className="input_box" onClick={() => removeError("basicQualification")}>
                  <p>BASIC QUALIFICATION&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <select id="cars" name="cars" value={addressData.basicQualification} onChange={(e) => setAddressData(prev => ({ ...prev, basicQualification: e.target.value }))}>
                    <option value={-1}>Select Qualification :</option>
                    <option value="X">X</option>
                    <option value="XII OR EQUIVALENT">XII or Equivalent</option>
                    <option value="GRADUATION"> Graduation</option>
                  </select>
                  {error.hasOwnProperty('basicQualification') ? (
                    <ErrorPopups error={error.basicQualification} />
                  ) : null}
                </div>
              </div>
              <div className="input_box_divider">
                <div className="input_box" onClick={() => removeError("dob")}>
                  <p>DATE OF BIRTH&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input type="date" id='number' className='input-date-dob'
                    name="begin" value={addressData.dob} onChange={(e) => {
                      const currentDate = new Date()
                      const selectedDate = new Date(e.target.value)

                      if (selectedDate < currentDate) {
                        setAddressData((prev) => ({
                          ...prev,
                          dob: e.target.value,
                        }));
                      } else {
                        setError({ dob: "Please select a date that is not in future" })
                        setAddressData((prev) => ({
                          ...prev,
                          dob: ''
                        }))
                      }
                    }}
                  />
                  {error.hasOwnProperty('dob') ? (
                    <ErrorPopups className="error-dob" error={error.dob} />
                  ) : null}
                </div>
                <div className=' input_box' onClick={() => removeError("pancard")}>
                  <p>PAN CARD&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id='house'
                    name='street '
                    pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                    value={addressData.pancard}
                    maxLength={10}
                    onChange={(e) => setAddressData(prev => ({ ...prev, pancard: e.target.value.toUpperCase() }))} />
                  {error.hasOwnProperty('pancard') ? (
                    <ErrorPopups error={error.pancard} />
                  ) : null}
                </div>

      <div className='input_box' onClick={() => removeError("mobNo")}>
        <p>MOBILE NUMBER&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
         <div className="num_mob_one">
    +91
  </div>
        <input
          type="tel"  // Use type "tel" to indicate a telephone number input
          id='house'
          name='street'
          className="mob_num_input_new"
          maxLength={10}
          value={addressData.mobNo}
          onChange={(e) => {
            const inputValue = e.target.value;
            const isValidInput = /^[0-9]*$/.test(inputValue); // Regular expression to allow only numbers
            if (isValidInput) {
              const formattedValue = /^[6-9]/.test(inputValue) ? inputValue.slice(0, 10) : "";
              setAddressData(prev => ({ ...prev, mobNo: formattedValue }))
            }
          }}
        />
        {error.hasOwnProperty('mobNo') ? (
          <ErrorPopups error={error.mobNo} />
        ) : null}
      </div>


{/* 
                <div className=' input_box' onClick={() => removeError("mobNo")}>
                  <p>MOBILE NUMBER&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <div className="mob_num_container">
                    <label className="num_mob">+91</label>
                    <input
                      type="text"
                      id='house'
                      className="mob_num_input"
                      name='street '
                      maxLength={10}
                      value={addressData.mobNo}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const isValidInput = /^[0-9]*$/.test(inputValue); // Regular expression to allow only numbers
                        if (isValidInput) {
                          const formattedValue = /^[6-9]/.test(inputValue) ? inputValue : "";
                          setAddressData(prev => ({ ...prev, mobNo: formattedValue }))
                        }
                      }} />
                    {error.hasOwnProperty('mobNo') ? (
                      <ErrorPopups error={error.mobNo} />
                    ) : null}
                  </div>
                </div> */}
              </div>
            </form>
            <div className="btn_register">
              <button step={1} onClick={clickHandle}>NEXT</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Basic