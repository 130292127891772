import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FeedbackTable = ({ selectedMenu }) => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch feedback data
  useEffect(() => {
    if (selectedMenu === 'customerFeeback') {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/feedback`)
        .then((response) => {
          setFeedbackData(response.data.data); // Assuming the API returns an array of feedback
        })
        .catch((error) => {
          console.error('Error fetching feedback data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedMenu]);

  // Handle approve action
  const handleApprove = (id) => {
    if (!id) return;
    axios
      .put(`${process.env.REACT_APP_API_URL}/feedback/${id}`, {
        isApproved: true,
      })
      .then((response) => {
        setFeedbackData((prev) =>
          prev.map((feedback) =>
            feedback._id === id ? { ...feedback, isApproved: true } : feedback
          )
        );
        alert('Feedback approved successfully');
      })
      .catch((error) => {
        console.error('Error updating feedback status:', error);
      });
  };

  // Generate table rows
  const newrow = feedbackData.map((feedback) => (
    <tr key={feedback.id}>
      <td className="t-l">
        <p>{feedback.firstName}</p>
      </td>
      <td className="t-l">
        <p>{feedback.lastName}</p>
      </td>
      <td className="t-l">
        <p>{feedback.city}</p>
      </td>
      <td className="t-l">
        <p>{feedback.rating}</p>
      </td>
      <td className="t-l">
        <p>{feedback.review}</p>
      </td>
      <td className="t-l">
        <button
          style={{
            padding: '5px 10px',
            cursor: 'pointer',
            backgroundColor: feedback.isApproved === true ? 'green' : 'blue',
            border: 'none',
            borderRadius: '5px',
          }}
          onClick={() => handleApprove(feedback._id)}
          disabled={feedback.isApproved === true}
        >
          {feedback.isApproved === true ? 'Approved' : 'Approve'}
        </button>
      </td>
    </tr>
  ));

  // Conditional rendering
  return selectedMenu === 'customerFeeback' ? (
    <div className="feedback-table">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="fit_content">
          <thead>
            <tr className="entries-container">
              <th className="fit_heading">
                <p>First Name</p>
              </th>
              <th className="fit_heading">
                <p>Last Name</p>
              </th>
              <th className="fit_heading">
                <p>City</p>
              </th>
              <th className="fit_heading">
                <p>Rating</p>
              </th>
              <th className="fit_heading">
                <p>Review</p>
              </th>
              <th className="fit_heading">
                <p>Approve action</p>
              </th>
            </tr>
          </thead>
          <tbody>{newrow}</tbody>
        </table>
      )}
    </div>
  ) : null;
};

export default FeedbackTable;
