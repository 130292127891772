import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FcOk } from 'react-icons/fc'
import { BsFillRecordCircleFill } from 'react-icons/bs'
// import{ AiOutlineArrowDown} from 'react-icons/ai'
import { useState, useEffect } from 'react';
import Sidebar from './Sidebar'
import { useDispatch, useSelector } from "react-redux"
import { updateLanguage } from "../../../../src/Redux/posRedux"
import { axiosPUTCall, axiosPUTCall1 } from "../../../utilities/utils/apiPost"
import ErrorPopups from './ErrorPopups';
// import { Link } from 'react-router-dom';


const Language = () => {
  // const [items3, setItems3] = useState(true)
  const [error, setError] = useState('')
  const [items2, setItems2] = useState(true)
  const posRedux = useSelector((state) => state.posRedux.posData);
  const [language, setLanguage] = useState({
    training: posRedux?.language?.training ? posRedux?.language?.training : "",
    exam: posRedux?.language?.exam ? posRedux?.language?.exam : ""
  })
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const clickHandle = () => {
    let obj = {
      language: {
        training: language.training,
        exam: language.exam
      }
    }
    if (!language.training) {
      setError({ training: 'Please Select Language' })
    }
    else if (!language.exam) {
      setError({ exam: 'Please Select Language' })
    }
    else {
      axiosPUTCall1('pospInfo/' + posRedux._id, obj, (callBack => {
        if (callBack.status === "Success") {
          dispatch(updateLanguage(callBack.data));
          // console.log("callback are bewfnlk. ,", callBack);
          navigate('/sengment')
        }
      }));
    }
  }
  const removeError = (key) => {
    switch (key) {
      case 'training':
        if (error.training) return setError(error.training = "")
        break
      case 'exam':
        if (error.exam) return setError(error.exam = "")
        break
      default:
        break;
    }
  }
  useEffect(() => {
    if (Object.keys(posRedux).length <= 0) {
      navigate('/partner')
    } else if (!posRedux.gender || !posRedux.dob || !posRedux.email || !posRedux.panNo || !posRedux.qualification || !posRedux.phone) {
      navigate('/details')
    } else if (!posRedux?.language?.training || !posRedux?.language?.exam) {
      navigate('/lang')
    } else if (!posRedux?.segment) {
      navigate('/sengment')
    } else if (!posRedux?.documents?.profile || !posRedux?.documents?.aadharFront || !posRedux?.documents?.pancard || !posRedux?.documents?.education || !posRedux?.documents?.signature) {
      navigate('/documents')
    }
  }, [])

  const cancelHandler = () => {
      const fromAdminDash = localStorage.getItem('addPos')

      if (fromAdminDash) {
        navigate('/dashboard/agent')
        localStorage.removeItem('addPos')
      } else {
      navigate('/work-with-us')
    }
  }
    
  return (
    <div className="mainWrapper">
      <div className="containerContent">
      <div className="cancelBtn" onClick={cancelHandler}>&times;</div>
        <Sidebar items2={items2} setItems2={setItems2} />

        <div className="pos_input_container">

          <h3>Language :</h3>

          <form action="">
            <div className="input_box_divider">
              <div className="input_box" onClick={() => removeError("training")}>
                <p>TRAINING LANGUAGE&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                <select id="language" name="language" value={language.training}  onChange={(e) => setLanguage(prev => ({ ...prev, training: e.target.value }))}>
                  <option value={-1}>Select Language :</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                  <option value="tamil">Tamil</option>
                  <option value="urdu">Urdu</option>
                </select>
                {error.hasOwnProperty('training') ? (
                  <ErrorPopups error={error.training} />
                ) : null}
              </div>
            </div>

            <div className="input_box_divider">
              <div className="input_box" onClick={() => removeError("exam")}>
                <p>EXAM LANGUGE&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                <select id="cars" name="cars" value={language.exam} onChange={(e) => setLanguage(prev => ({ ...prev, exam: e.target.value }))}>
                  <option value={-1}>Select Language :</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                  <option value="tamil">Tamil</option>
                  <option value="urdu">Urdu</option>
                </select>
                {error.hasOwnProperty('exam') ? (
                  <ErrorPopups error={error.exam} />
                ) : null}
              </div>
            </div>

          </form>
          <div className="btn_register" onClick={clickHandle}>
            <button step={3} onClick={clickHandle}>NEXT</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Language