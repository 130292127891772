import React from 'react';
import './feadback.css';

const StatisticsPage = () => {
  return (
    <div className="statistics-container">
        <h1 className='stats-heading'>PROTECTING FAMILIES AND BUSINESSES ACROSS INDIA</h1>
      <section className="content-stats">
        <div className="map-background">
          <div className="stats-cards">
            <div className="card-stats">
              <h2>1,00,000 + </h2>
              <p>Registered Users</p>
              <span>
                Across India, users trust Simplinsure.com to secure their lives
                and assets.
              </span>
            </div>
            <div className="card-stats">
              <h2>15 CR+</h2>
              <p>Policies Managed</p>
              <span>
                Worth of insurance policies managed seamlessly across
                categories.
              </span>
            </div>
            <div className="card-stats">
              <h2>2 CR+</h2>
              <p>Policies Purchased</p>
              <span>Monthly, safeguarding lives and businesses.</span>
            </div>
            <div className="card-stats">
              <h2>Efficient Service</h2>
              <p>Speed of Service</p>
              <span>
                New policies issued daily, demonstrating efficiency and trust.
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StatisticsPage;
