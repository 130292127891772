import React, {useEffect } from 'react'
import { CiImageOn } from 'react-icons/ci'
import { useNavigate, useLocation } from 'react-router-dom'
import SubmittedGif from '../components/submitted.gif'

import './Pos.css'

const Pos = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const {examStatus} = location.state || {}
  useEffect(() => {
    if(examStatus == "completed"){
    const timer = setTimeout(() => {
      navigate('/review');
    }, 2000); // 2 seconds

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }
  }, [navigate]);
  return (
    <>
      <div className="main7">
        <div className="Pos">
          <div className="Pos1">
            <img src={SubmittedGif} alt="SubmittedGif" />
          </div>
          <div className="Pos2">
            <p>Your application has been successfully submitted.</p>
          </div>
          {/* width="140" height="1395" */}
          {/* decoding="sync"  */}
          <div className="Pos3">
           {examStatus == "pending" && <div><p>Please hold off on further action until we generate and provide the training link. Thank you for your patience!</p></div>}
           {examStatus == "approved" && <div>
            <p>The training link is now ready! Please proceed using the link provided. Thank you for your patience!</p>
            <div className="Pos4">
              <span>Training Link: </span>
              <a href="https://simpliinsure.groomlms.com" target="_blank">
              https://simpliinsure.groomlms.com
              </a>  
              </div>
            </div>}
            </div>
        </div>
      </div>
    </>
  );
}

export default Pos