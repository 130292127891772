import React, { useRef } from 'react';
import './feadback.css';
import StatisticsPage from './StatisticsPage';
import Testimonials from './Testimonial';
import FeedbackForm from './FeadbackForm';
import FooterNew from './../../components/Footer/FooterNew';
import lastFooterImg from './../../assets/lastBanner.png';

const CustomerFeadback = () => {
  const feedbackFormRef = useRef(null);

  const scrollToFeedbackForm = () => {
    feedbackFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className="feadback_stats_container">
        <div className="upper-section">
          <div className="text-overlay">
            <h1>Your Voice Matters! Help Us Serve You Better.</h1>
            <p>
              Together, we can create a more secure and inclusive insurance
              ecosystem. Your reviews help us deliver smarter, faster, and more
              personalized insurance solutions.
            </p>
            <ul>
              <li>
                <strong>B2C Customers:</strong> Share how we simplified your
                insurance journey.
              </li>
              <li>
                <strong>B2B Partners:</strong> Let us know how our partnerships
                are helping you achieve your goals.
              </li>
            </ul>
            <button className="submit-btn" onClick={scrollToFeedbackForm}>
              Submit Review
            </button>
          </div>
        </div>
      </div>
      <StatisticsPage />
      <Testimonials />
      <div ref={feedbackFormRef}>
        <FeedbackForm />
      </div>
      <div className="lastBannerSection">
        <img
          className="lastBannerSectionImg"
          src={lastFooterImg}
          alt="feadback-bg"
        />
      </div>
      <FooterNew />
    </>
  );
};

export default CustomerFeadback;
