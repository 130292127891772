import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/simpli_logo_icon.svg";
import "./Header.css";
import HamburgerIcon from "../../assets/commons/hamburger.png";
import closeIcon from "../../assets/commons/close2.png";
import styled from "styled-components";
import Profile from "../../assets/profile.png";
import { useNavigate } from "react-router-dom";
import { axiosGETCall1 } from "../../utilities/utils/apiPost"
import { menuItems } from "./menuItems";
import MenuItems from "./MenuItem";



const Header = () => {
  const navigate = useNavigate();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const [navbarActive, setNavbarActive] = useState(false);
  const [isLogIn, setIsLogIn] = useState(false);
  // const [isPos, setIsPos] = useState(true);
  const [status, setStatus] = useState({
    role: "",
    isUserPos: true,
    isUser: true,
    isKyc: "",
    isIrda: "",
  })
  const [isVerifiedPos, setIsVerifiedPos] = useState(false);
  // const [isUserPos, setIsUserPos] = useState(false);
  const [posStatus, setPosStatus] = useState("NA");
  // console.log("🚀 ~ Header ~ posStatus:", posStatus)
  let userData = localStorage.getItem('userDetails');
  userData = JSON.parse(userData);
  let Authorization = localStorage.getItem("Authorization");

if(!isLogIn && Authorization){
  setIsLogIn(true);
}
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  // const handlePos = () => {
  //   setIsPos(true);
  //   navigate("/signin-signup?isPos=" + isPos);
  // };
  const handleDash = () => {
    setNavbarActive(false);
    navigate("/dashboard/agent")
  }

  // for the placeholder name
  const getFirstLetter = (str) => {
    return str ? str[0].toUpperCase() : '';
  };


  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize.dynamicWidth < 980) {
      setHamburgerActive(true);
    } else if (screenSize.dynamicWidth >= 980) {
      setHamburgerActive(false);
    }
  }, [screenSize]);

  useEffect(() => {
    if (userData) {
      axiosGETCall1(`/user/` + `${userData.userId || userData._id}`, {}, (callBack => {
        if (callBack.status == "success") {
          userData = callBack.data
        }
      }));
    }
  }, [])
  useEffect(() => {
    if (Authorization) {
      setIsLogIn(true);
    } else {
      setIsLogIn(false);
    }

    if (userData && Object.keys(userData).length > 0 && userData?.userId) {
      if (userData?.roles?.includes("user")) {
        setPosStatus(userData.posStatus || "NA")
        setStatus(prev => ({ ...prev, role: userData?.roles[0] }))
      }
      if (userData.roles?.includes("pos")) {
        setStatus(prev => ({ ...prev, role: userData?.roles[0], isUserPos: true, isUser: false }))
        setPosStatus(userData.posStatus || "NA")
      }
      if (userData?.roles?.includes("admin")) {
        setStatus(prev => ({ ...prev, role: "admin" }))
        setIsVerifiedPos(true)
      }
      else if (userData?.roles?.includes("admin") && userData.irdaStatus === "approved") {
        setStatus(prev => ({ ...prev, role: "admin", isIrda: true }))
        setIsVerifiedPos(true);
      }
    }
  }, [isLogIn]);


  const navRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavbarActive(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div style={{ marginBottom: '50px' }}>
      <nav className="nav-wrapper">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        {screenSize.dynamicWidth >= 980 ? (
          <div className="links">
            <ul className="menus">
              {menuItems.map((menu, index) => {
                const depthLevel = 0;
                return (
                  <MenuItems items={menu} key={index} depthLevel={depthLevel} />
                );
              })}
            </ul>

            {isLogIn ? (
              <>
                <div
                  className="profile_header"
                  onClick={() => navigate('/dashboard/agent')}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <img
                    style={{
                      height: 45,
                      width: 45,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src={
                      userData?.photo
                        ? userData?.photo
                        : `https://placehold.co/600x400?text=${getFirstLetter(
                            userData?.fullName
                          )}`
                    }
                    alt="fullName"
                  />
                  <div className="rolesAndName">
                    <p className="headerTitle">
                      {userData?.fullName.split(' ')[0]}
                    </p>
                    <div>
                      {userData?.roles && (
                        <p className="rolesPara">
                          {userData.roles.includes('admin') &&
                          userData.roles.includes('pos')
                            ? 'POS'
                            : null}
                          {userData.roles.includes('rm') ? 'RM' : null}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Link to="/signin-signup?sourcePage=header">
                <button>Login</button>
              </Link>
            )}
          </div>
        ) : null}

        {hamburgerActive ? (
          <Hamburger
            ref={navRef}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setNavbarActive((prev) => !prev);
            }}
            src={navbarActive ? closeIcon : HamburgerIcon}
          />
        ) : null}
      </nav>
      {navbarActive ? (
        <div className="links">
          <ul className="menus">
            {menuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems items={menu} key={index} depthLevel={depthLevel} />
              );
            })}
          </ul>
          {/* {isVerifiedPos ? (
            null
          ) : isLogIn ? (
            <>
              <Link to="/partner" className="header-menu pos" onClick={() => setNavbarActive(prev => !prev)}>
                Register as POS
              </Link>{" "}
            </>
          ) : (
            <div className="header-menu  pos" onClick={handlePos}>
              Register as POS
            </div>
          )} */}
          {/* <Link to='/signin-signup' className='header-menu'><a href='' className='pos'>Register as POS</a> </Link>   */}
          {isLogIn ? (
            <div className="dash">
              <img
                onClick={handleDash}
                style={{
                  height: 40,
                  width: 40,
                  cursor: 'pointer',
                  borderRadius: '50%',
                  border: '2px solid #F6931E',
                }}
                src={userData?.photo ? userData?.photo : Profile}
                alt="profile_photo"
              />
            </div>
          ) : (
            <Link to="/signin-signup">
              <button onClick={() => setNavbarActive((prev) => !prev)}>
                Login
              </button>
            </Link>
          )}
        </div>
      ) : screenSize.dynamicWidth > 980 ? (
        <div className="links">
          <ul className="menus">
            {menuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems items={menu} key={index} depthLevel={depthLevel} />
              );
            })}
          </ul>
          {/* {isLogIn && isVerifiedPos ? (
            <>
              <Link to="/partner" className="header-menu  pos">
                Register as POS
              </Link>{" "}
            </>
          ) :
            (
              <div className="header-menu  pos" onClick={handlePos}>
                Register as POS
              </div>
            )} */}
          {/* <Link to='/partner' className='header-menu'><a href='' className='pos'>Register as POS</a></Link> */}

          <Link to="/signin-signup">
            <button>Login</button>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

const Hamburger = styled.img`
  border: 1px solid;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
`;

export default Header;
