import React from 'react'
// import {Link} from 'react-router-dom'
// import{FcOk} from 'react-icons/fc'

// import {BsFillRecordCircleFill} from 'react-icons/bs'
// import{ AiOutlineArrowDown} from 'react-icons/ai'
import { useState } from 'react';
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { updateSegment } from "../../../../src/Redux/posRedux"
import { axiosPUTCall, axiosPUTCall1 } from "../../../utilities/utils/apiPost"
import ErrorPopups from './ErrorPopups';
import { useEffect } from 'react';
import SegmentComponent from '../../segment-dropdown/segmentDropdown';

const SEGMENT = () => {

  const dispatch = useDispatch();
  const [items3, setItems3] = useState(true)
  const [items2, setItems2] = useState(true)
  const [error, setError] = useState('')
  const [segment, setSegment] = useState([])
  const posRedux = useSelector((state) => state.posRedux.posData);
  // console.log(segment);
  // const [items4,setItems4] = useState(true)
  // const [items,setItems] =useState(false)
  // const [page, setPage] = useState(false)
  // const [contact,SetContact] = useState(false)
  // const [data , setData] = useState(false)

  const navigate = useNavigate()



const clickHandle = () => {
  if (!segment || segment.length === 0) {
    setError({ segment: 'Please Select Segment' });
  } else {
    axiosPUTCall1(
      'pospInfo/' + posRedux._id,
      { segment: segment },
      (callBack) => {
        if (callBack.status === 'Success') {
          dispatch(updateSegment(callBack.data));
          navigate('/documents');
        }
      }
    );
    setItems2(false);
    setItems3(false);
  }
};

const removeError = (key) => {
  if (key === 'segment' && error.segment) {
    setError((prevError) => ({ ...prevError, segment: '' }));
  }
};


  useEffect(() => {
    if (Object.keys(posRedux).length <= 0) {
      navigate('/partner')
    } else if (!posRedux.gender || !posRedux.dob || !posRedux.email || !posRedux.panNo || !posRedux.qualification || !posRedux.phone) {
      navigate('/details')
    } else if (!posRedux?.language?.training || !posRedux?.language?.exam) {
      navigate('/lang')
    } else if (!posRedux?.segment || posRedux?.segment.length === 0) {
      navigate('/sengment')
    } else if (!posRedux?.documents?.profile || !posRedux?.documents?.aadharFront || !posRedux?.documents?.pancard || !posRedux?.documents?.education || !posRedux?.documents?.signature) {
      navigate('/documents')
    }
  }, [posRedux, navigate])

  const cancelHandler = () => {
      const fromAdminDash = localStorage.getItem('addPos')

      if (fromAdminDash) {
        navigate('/dashboard/agent')
        localStorage.removeItem('addPos')
      } else {
      navigate('/work-with-us')
    }
  }

  return (
    <>
      {' '}
      <div className="mainWrapper">
        <div className="containerContent">
          <div className="cancelBtn" onClick={cancelHandler}>
            &times;
          </div>
          <Sidebar
            items2={items2}
            setItems2={setItems2}
            items3={items3}
            setItems3={setItems3}
          />

          <div className="pos_input_container">
            <h3>Segment :</h3>
            <form action="">
              <div
                className="segment_box"
                onClick={() => removeError('segment')}
              >
                <SegmentComponent setSegment={setSegment} />

                {error.hasOwnProperty('segment') ? (
                  <ErrorPopups error={error.training} />
                ) : null}
              </div>
            </form>
            <div className="btn_register">
              <button step={4} onClick={clickHandle}>
                NEXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SEGMENT