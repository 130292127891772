import React, { useEffect, useState } from 'react';
import './policyDetailsSection.css';
import axios from 'axios';
// import insuranceCompanies from './../../../utilities/insuranceCompany';



// const branchData = [
//   {
//     id: '3',
//     branch:
//       'Bangalore No. 25/1, 2nd fl., bldng no. 2, Shankarnarayana bldng no. 1, MG Rd',
//   },
//   {
//     id: '53',
//     branch:
//       'Nagpur 8th floor, Shriram Tower, 702 A-Wing, Kingsway, Sadar, Nagpur Nagpur',
//   },
// ];

 const motorPolicies = [
   {
     value: 'Motor Commercial Vehicle (Goods Carrier) Policy',
     label: 'Motor Commercial Vehicle (Goods Carrier) Policy',
   },
   {
     value: 'Motor Commercial Vehicle (Passenger) Policy',
     label: 'Motor Commercial Vehicle (Passenger) Policy',
   },
   { value: 'Motor Miscellaneous Policy', label: 'Motor Miscellaneous Policy' },
   {
     value: 'Motor Private Car Package Policy',
     label: 'Motor Private Car Package Policy',
   },
   {
     value: 'Motor Third Party Liability Policy',
     label: 'Motor Third Party Liability Policy',
   },
   { value: 'Motor Two Wheeler Policy', label: 'Motor Two Wheeler Policy' },
   { value: 'Private Car Liability Only', label: 'Private Car Liability Only' },
   { value: 'STANDALONE POLICY', label: 'STANDALONE POLICY' },
   { value: 'Two Wheeler Liability Only', label: 'Two Wheeler Liability Only' },
 ];
 

const PolicyDetailsSection = ({ formData, handleChange }) => {
  const [insurerData, setInsurerData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [selectedInsurer, setSelectedInsurer] = useState('');
  const [selectedCity, setSelectedCity] = useState('');


  useEffect(() => {
       if (formData.startDate && formData.days) {
         const startPolicyDate = new Date(formData.startDate);
         if (formData.startDate && formData.days) {
          const minusOneDay = formData.days - 1;
           const expiryDate = new Date(startPolicyDate);
           expiryDate.setDate(
             startPolicyDate.getDate() + parseInt(minusOneDay, 10)
           );

           handleChange({
             target: {
               name: 'endDate',
               value: expiryDate.toISOString().split('T')[0],
             },
           });
         } else {
           handleChange({ target: { name: 'endDate', value: '' } });
         }
       }
  }, [formData.startDate, formData.days]);
 

  // Fetch insurer data from API
  useEffect(() => {
    const fetchInsurerDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/insurerDetails`);
        setInsurerData(response?.data?.data); // Set the fetched data
      } catch (error) {
        console.error('Failed to fetch insurer details:', error);
      }
    };

    fetchInsurerDetails();
  }, []);

  // Handle Insurer selection
  const handleInsurerChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedInsurer(selectedValue);
    handleChange(e);

    // Filter cities based on selected insurer
    const cities = Array.from(
      new Set(
        insurerData
          .filter((insurer) => insurer.Insurer === selectedValue)
          .map((insurer) => insurer.City)
      )
    );
    setFilteredCities(cities);
    setFilteredBranches([]); // Reset branches
    setSelectedCity(''); // Reset selected city
    handleChange({ target: { name: 'insurerCity', value: '' } }); // Reset city field
    handleChange({ target: { name: 'insurerBranch', value: '' } }); // Reset branch field
  };

  // Handle City selection
  const handleCityChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCity(selectedValue);
    handleChange({ target: { name: 'insurerCity', value: selectedValue } });

    // Filter branches based on selected city
    const branches = insurerData
      .filter(
        (insurer) =>
          insurer.Insurer === selectedInsurer && insurer.City === selectedValue
      )
      .map((insurer) => ({
        id: insurer.BranchAutoCode,
        address: insurer.Address,
      }));
    setFilteredBranches(branches);
    handleChange({ target: { name: 'insurerBranch', value: '' } }); // Reset branch field
  };


  return (
    <div className="policy-details-section">
      <h2>Policy Details</h2>
      <div className="policy-details-grid">
        <div className="form-group required">
          <label htmlFor="insurerName" className="policyDetailsLabel">
            Insurer Name <span className="required-strick">*</span>
          </label>
          <select
            id="insurerName"
            name="insurerName"
            className="policyDetailsSelect"
            value={formData.insurerName}
            onChange={handleInsurerChange}
            required
          >
            <option value="">--Select--</option>
            {Array.from(
              new Set(insurerData.map((insurer) => insurer.Insurer))
            ).map((insurerName, index) => (
              <option key={index} value={insurerName}>
                {insurerName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="insurerCity" className="policyDetailsLabel">
            Insurer City
          </label>
          <select
            id="insurerCity"
            name="insurerCity"
            className="policyDetailsSelect"
            value={selectedCity}
            onChange={handleCityChange}
            required
            disabled={!filteredCities.length} // Disable if no cities are available
          >
            <option value="">--Select--</option>
            {filteredCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="insurerBranch" className="policyDetailsLabel">
            Insurer Branch <span className="required-strick">*</span>
          </label>
          <select
            id="insurerBranch"
            name="insurerBranch"
            className="policyDetailsSelect"
            value={formData.insurerBranch}
            onChange={handleChange}
            required
            disabled={!filteredBranches.length} // Disable if no branches are available
          >
            <option value="">--Select--</option>
            {filteredBranches.map((branch) => (
              <option key={branch.id} value={branch.address}>
                {branch.address}
              </option>
            ))}
          </select>
          <button className="add-btn">+</button>
        </div>

        <div className="form-group required">
          <label htmlFor="busBookDate" className="policyDetailsLabel">
            Bus. Book Date <span className="required-strick">*</span>
          </label>
          <input
            type="date"
            id="busBookDate"
            name="busBookDate"
            className="policyDetailsInput"
            value={formData.busBookDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="startDate" className="policyDetailsLabel">
            Start Date <span className="required-strick">*</span>
          </label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            className="policyDetailsInput"
            value={formData.startDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="department" className="policyDetailsLabel">
            Department <span className="required-strick">*</span>
          </label>
          <select
            id="department"
            name="department"
            className="policyDetailsSelect"
            value={formData.department}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Credit">Credit</option>
            <option value="Engineering">Engineering</option>
            <option value="Fire">Fire</option>
            <option value="Health">Health</option>
            <option value="Liability">Liability</option>
            <option value="Marine Cargo">Marine Cargo</option>
            <option value="Marine Hull">Marine Hull</option>
            <option value="Miscellaneous">Miscellaneous</option>
            <option value="motor">Motor</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="days" className="policyDetailsLabel">
            Days
          </label>
          <input
            type="number"
            id="days"
            name="days"
            className="policyDetailsInput"
            value={formData.days}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="endDate" className="policyDetLabel">
            Expiry Date
          </label>
          <input
            type="date"
            id="endDate"
            className="policyDetailsInput"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="policyType" className="policyDetailsLabel">
            Policy Type <span className="required-strick">*</span>
          </label>
          <select
            id="policyType"
            name="policyType"
            className="policyDetailsSelect"
            value={formData.policyType}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            {formData.department === 'motor' ? (
              motorPolicies.map((policy) => (
                <option key={policy.value} value={policy.value}>
                  {policy.label}
                </option>
              ))
            ) : (
              <option value="NA">NA</option>
            )}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="isRenewable" className="policyDetailsLabel">
            Is Renewable
          </label>
          <select
            id="isRenewable"
            name="isRenewable"
            className="policyDetailsSelect"
            value={formData.isRenewable}
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="policyNo" className="policyDetailsLabel">
            Policy No.
          </label>
          <input
            type="text"
            id="policyNo"
            name="policyNo"
            className="policyDetailsInput"
            value={formData.policyNo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="hypothecation" className="policyDetailsLabel">
            Hypothecation
          </label>
          <select
            id="hypothecation"
            name="hypothecation"
            className="policyDetailsSelect"
            value={formData.hypothecation}
            onChange={handleChange}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="projectName" className="policyDetailsLabel">
            Project Name
          </label>
          <input
            type="text"
            id="projectName"
            name="projectName"
            className="policyDetailsInput"
            value={formData.projectName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="policyExcess" className="policyDetailsLabel">
            Policy Excess
          </label>
          <input
            type="text"
            id="policyExcess"
            name="policyExcess"
            className="policyDetailsInput"
            value={formData.policyExcess}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredLocation" className="policyDetailsLabel">
            Insured Location
          </label>
          <input
            type="text"
            id="insuredLocation"
            name="insuredLocation"
            className="policyDetailsInput"
            value={formData.insuredLocation}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="policyRemarks" className="policyDetailsLabel">
            Policy/Enquiry Remarks
          </label>
          <input
            type="text"
            id="policyRemarks"
            name="policyRemarks"
            className="policyDetailsInput"
            value={formData.policyRemarks}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyDetailsSection;
