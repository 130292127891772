import React from "react";
import { GrNext, GrPrevious } from 'react-icons/gr';

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = Math.ceil(totalItems / itemsPerPage);
    const MAX_DISPLAY_PAGES = 3; // Number of page links to display around the current page

    if (pageNumbers <= 1) {
        // Hide pagination if only one page
        return null;
    }

    const getPageNumbers = () => {
        let pages = [];

        if (pageNumbers <= MAX_DISPLAY_PAGES) {
            // If total page numbers are less than or equal to MAX_DISPLAY_PAGES, show all pages
            pages = Array.from({ length: pageNumbers }, (_, index) => index + 1);
        } else {
            // Add first page
            pages.push(1);

            // Add dots if current page is more than 2
            if (currentPage > 2) {
                pages.push('...');
            }

            // Calculate range of pages to display around the current page
            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(pageNumbers - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            // Add dots if current page is less than total pages - 1
            if (currentPage < pageNumbers - 1) {
                pages.push('...');
            }

            // Add last page
            pages.push(pageNumbers);
        }

        return pages;
    };

    const displayedPages = getPageNumbers();

    return (
        <div className="">
            <ul className="paginate-list">
                <button className="paginate-button" onClick={() => currentPage > 1 && paginate(currentPage - 1)}>
                    <GrPrevious className="paginate-icons" />
                </button>
                {displayedPages.map((number, index) => (
                    <li key={index}>
                        {number === '...' ? (
                            <span className="paginate-dots">...</span>
                        ) : (
                            <button className={`paginate-numbers ${currentPage === number ? 'active' : ''}`} onClick={() => paginate(number)}>
                                {number}
                            </button>
                        )}
                    </li>
                ))}
                <button className="paginate-button" onClick={() => currentPage < pageNumbers && paginate(currentPage + 1)}>
                    <GrNext className="paginate-icons" />
                </button>
            </ul>
        </div>
    );
};
export default Pagination;