import Pagination from "./pagination";
// import { SkeletonComponent } from '../../pages/dashboard/agent-dashboard/SkeletonComponent';
import { useState, useEffect } from "react";
import './style.css'
import { Select } from "antd";
import Search from './Search';
import { fetchData, fetchMapCount } from './Promises';
import { CustCcSelect, CustFuelSelect, CustModelSelect, CustSelect, CustVariantSelect } from './CustSelect';

export const AdminDashboard = () => {
    const [arrAll, setArr] = useState([])
    const [showFullText, setShowFullText] = useState(false);
    const [showFullModelText, setShowFullModelText] = useState(false);
    const [data, setData] = useState([]);
    const [companies, setCompanies] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [temp, setTemp] = useState();
    const [slug, setSlug] = useState("");
    const [count, setCount] = useState();

    const [searchBrand, setSearchBrand] = useState("");
    const [searchModel, setSearchModel] = useState("");
    const [searchCc, setSearchCc] = useState("");
    const [searchFuel, setSearchFuel] = useState("");
    const [searchVariant, setSearchVariant] = useState("");

    const handleChange = (value) => {
        const slugMapping = {
            "HDFC": "motor-hdfc",
            "ICICI": "motor-icici",
            "KOTAK": "motor-kotak",
            "Go Digit": "motor-goDigit",
            "Reliance": "motor-reliance"
        }
        const temSlup = slugMapping[value]
        setCompanies(value)
        setSlug(temSlup)
    }

    // Pagination logic
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        fetchData({ page: currentPage, limit: itemsPerPage, searchBrand, searchModel, searchCc, searchFuel, searchVariant }).then((response) => {
            setArr(response.data)
            setData(response.len)
        })
        fetchMapCount({ slug: slug }).then((response) => {
            setCount(response.count)
        })
    }, [currentPage, searchBrand, searchModel, searchCc, searchFuel, searchVariant, temp, slug])


    return (
        <>
            <div style={{ marginTop: '3rem', marginLeft: '5px' }}>
                <div className="flex c-gap main-container">
                    <div className="left f-grow-1" style={{ marginTop: '10px' }} >
                        <h1 className="master-title">Master Data</h1>
                        <div className="table_body" >
                            <table className="fit_content" style={{ borderCollapse: 'collapse' }}>
                                <thead >
                                    <tr className="entries-container " >
                                        <th className="fit_heading_search" ><Search onSearchChange={(e) => setSearchBrand(e.target.value)} placeholder='Brand' /></th>
                                        <th className="fit_heading_search" ><Search onSearchChange={(e) => setSearchModel(e.target.value)} placeholder='Model' /></th>
                                        <th className="fit_heading_search" ><Search onSearchChange={(e) => setSearchFuel(e.target.value)} placeholder='Fuel' /></th>
                                        <th className="fit_heading_search" ><Search onSearchChange={(e) => setSearchCc(e.target.value)} placeholder='CC' /></th>
                                        <th className="fit_heading_search" ><Search onSearchChange={(e) => setSearchVariant(e.target.value)} placeholder='Variant' /></th>
                                    </tr>
                                    <tr className="entries-container " >
                                        <th className="fit_heading" ><p>Make/Brand</p></th>
                                        <th className="fit_heading" ><p>Model</p></th>
                                        <th className="fit_heading" ><p>Fuel Type</p></th>
                                        <th className="fit_heading" ><p>CC</p></th>
                                        <th className="fit_heading" ><p>Variant</p></th>
                                    </tr>
                                </thead>
                                <tbody className='master-table' >
                                    {arrAll?.map((val, i) =>
                                        <tr className="trow" key={i}>
                                            <td className="master-trow brand" >{val.makeName}</td>
                                            {val.modelName?.toString().length < 13 ? (
                                                <td className="master-trow model">{val.modelName}</td>
                                            ) : (
                                                <td className="master-trow model" title={val.modelName}>
                                                    <span
                                                        className="model-text"
                                                    >
                                                        {val.modelName.toString().slice(0, 12)}&nbsp;...
                                                    </span>
                                                </td>
                                            )}

                                            <td className="master-trow">{val.fuelType}</td>
                                            <td className="master-trow cc">{val.cubicCap}</td>
                                            {val.variantName.length <26 ?
                                                <td className="master-trow varient">{val.variantName}</td> :
                                                <td className="master-trow varient" title={val.variantName}>
                                                    <span  className="model-text">
                                                        {val.variantName.toString().slice(0, 25)}&nbsp;...
                                                    </span>
                                                </td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    < div className="right f-grow-1" style={{ marginTop: companies ? "5px" : "36px" }}>
                        <h1 className="master-title">{companies}</h1>
                        <div className="slect-mappCount">
                            <Select
                                options={[
                                    { value: "HDFC", key: 1 },
                                    { value: "ICICI", key: 2 },
                                    { value: "KOTAK", key: 3 },
                                    { value: "Go Digit", key: 4 },
                                    { value: "Reliance", key: 5 },
                                ]}
                                placeholder={'Select Company'}
                                style={{ width: 200, marginTop: "11px", marginBottom: "2px" }}
                                onChange={handleChange}
                                value={companies}
                                className='select-comp'></Select>
                            <p>Mapped Count: {count}</p>
                        </div>
                        <>
                            <div className="table_body">
                                <table className="fit_content" style={{ borderCollapse: 'collapse' }}>
                                    <thead >
                                        <tr className="entries-container ">
                                            <th className="fit_heading"><p>Make/Brand</p></th>
                                            <th className="fit_heading"><p>Model</p></th>
                                            <th className="fit_heading"><p>Fuel Type</p></th>
                                            <th className="fit_heading"><p>CC</p></th>
                                            <th className="fit_heading"><p>Variant</p></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {arrAll?.map((val, index) => {
                                            if (!companies || !(val?.mappings?.[slug])) {
                                                const MakeName = '', ModelNameV2 = '', FuelTypeId = '', cc = '', VariantName = '';
                                                return (
                                                    <tr className={`trow`} key={index}>
                                                        <td className={`master-trow ${MakeName ? 'green' : null}`}>
                                                            <CustSelect value={MakeName ? { makeName: MakeName, company: companies } : { makeName: '', modelName: '', fuelType: '', cc: '' }}
                                                                index={val._id}
                                                                setDependancy={e => setTemp(!temp)}
                                                                className={`dropdown-sele brand`}
                                                                slug={slug} />
                                                        </td>
                                                        <td className={`master-trow ${ModelNameV2 ? 'green' : null}`}>
                                                            <CustModelSelect value={ModelNameV2 ? { modelName: ModelNameV2 } : { makeName: MakeName, modelName: '', fuelType: '', cc: '' }}
                                                                index={val._id}
                                                                setDependancy={e => setTemp(!temp)}
                                                                className={`dropdown-sele brand`}
                                                                slug={slug} />
                                                        </td>
                                                        <td className={`master-trow ${FuelTypeId ? 'green' : null}`}>
                                                            <CustFuelSelect value={FuelTypeId ? { fuelType: FuelTypeId } : { makeName: MakeName, modelName: ModelNameV2, fuelType: '', cc: '' }}
                                                                index={val._id}
                                                                setDependancy={e => setTemp(!temp)}
                                                                className={`dropdown-sele brand`}
                                                                slug={slug} />
                                                        </td>
                                                        <td className={`master-trow ${cc ? 'green' : null}`}>
                                                            <CustCcSelect value={cc ? { cc: cc } : { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId, cc: '' }}
                                                                index={val._id}
                                                                setDependancy={e => setTemp(!temp)} className={`dropdown-sele brand`}
                                                                slug={slug} />
                                                        </td>
                                                        <td className={`master-trow ${VariantName ? 'green' : null}`}>
                                                            <CustVariantSelect value={VariantName ? { variantName: VariantName } : { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId, cc: cc }}
                                                                index={val._id}
                                                                setDependancy={e => setTemp(!temp)}
                                                                className={`dropdown-sele brand`}
                                                                slug={slug} />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            const { MakeName, ModelNameV2, FuelTypeId, cc, VariantName } = val.mappings[slug];
                                            return (
                                                <tr className={`trow`} key={index}>
                                                    <td className={`master-trow ${MakeName ? 'green' : null}`}>
                                                        <CustSelect value={MakeName ? { makeName: MakeName, company: companies } : { makeName: '', modelName: '', fuelType: '', cc: '' }}
                                                            index={val._id}
                                                            setDependancy={e => setTemp(!temp)}
                                                            className={`dropdown-sele brand`}
                                                            slug={slug} />
                                                    </td>
                                                    <td className={`master-trow ${ModelNameV2 ? 'green' : null}`}>
                                                        <CustModelSelect value={ModelNameV2 ? { makeName: MakeName, modelName: ModelNameV2 } : { makeName: MakeName, modelName: '', fuelType: '', cc: '' }}
                                                            index={val._id}
                                                            setDependancy={e => setTemp(!temp)}
                                                            className={`dropdown-sele brand`}
                                                            slug={slug} />
                                                    </td>
                                                    <td className={`master-trow ${FuelTypeId ? 'green' : null}`}>
                                                        <CustFuelSelect value={FuelTypeId ? { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId } : { makeName: MakeName, modelName: ModelNameV2, fuelType: '', cc: '' }}
                                                            index={val._id}
                                                            setDependancy={e => setTemp(!temp)}
                                                            className={`dropdown-sele brand`}
                                                            slug={slug} />
                                                    </td>
                                                    <td className={`master-trow ${cc ? 'green' : null}`}>
                                                        <CustCcSelect value={cc ? { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId, cc: cc } : { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId, cc: '' }}
                                                            index={val._id}
                                                            setDependancy={e => setTemp(!temp)} className={`dropdown-sele brand`}
                                                            slug={slug} />
                                                    </td>
                                                    <td className={`master-trow ${VariantName ? 'green' : null}`}>
                                                        <CustVariantSelect value={VariantName ? { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId, cc: cc, variantName: VariantName } : { makeName: MakeName, modelName: ModelNameV2, fuelType: FuelTypeId, cc: cc }}
                                                            index={val._id}
                                                            setDependancy={e => setTemp(!temp)}
                                                            className={`dropdown-sele brand`}
                                                            slug={slug} />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </div>

                </div>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={data}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div >
        </>
    )
}
