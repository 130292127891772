import React from 'react';
import './brokerageDetailsSection.css';

const BrokerageDetailsSection = ({ formData, handleChange }) => {
  return (
    <div className="brokerage-details-section">
      <h2>Brokerage Details</h2>
      <div className="brokerage-details-grid">
        <div className="form-group">
          <label htmlFor="brokerageType" className="brokerageDetailsLabel">
            Brok./Fee
          </label>
          <select
            id="brokerageType"
            name="brokerageType"
            className="brokerageDetailsSelect"
            value={formData.brokerageType}
            onChange={handleChange}
          >
            <option value="Brokerage">Brokerage</option>
            <option value="Fee">Fee</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="brokerageRemarks" className="brokerageDetailsLabel">
            Brokerage/Fee Remarks
          </label>
          <input
            type="text"
            id="brokerageRemarks"
            name="brokerageRemarks"
            className="brokerageDetailsInput"
            value={formData.brokerageRemarks}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="gstSTax" className="brokerageDetailsLabel">
            GST/S. Tax %
          </label>
          <input
            type="number"
            id="gstSTax"
            name="gstSTax"
            className="brokerageDetailsInput"
            value={formData.gstSTax}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group required">
          <label htmlFor="brokerageRate" className="brokerageDetailsLabel">
            Brok. Rate%
          </label>
          <input
            type="number"
            id="brokerageRate"
            name="brokerageRate"
            className="brokerageDetailsInput"
            value={formData.brokerageRate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="brokerageAmount" className="brokerageDetailsLabel">
            Brok. Amount
          </label>
          <input
            type="number"
            id="brokerageAmount"
            name="brokerageAmount"
            className="brokerageDetailsInput"
            value={formData.brokerageAmount}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="tpBrokerageRate" className="brokerageDetailsLabel">
            TP Brok. Rate
          </label>
          <input
            type="number"
            id="tpBrokerageRate"
            name="tpBrokerageRate"
            className="brokerageDetailsInput"
            value={formData.tpBrokerageRate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="tpBrokerageAmount" className="brokerageDetailsLabel">
            TP Brok. Amount
          </label>
          <input
            type="number"
            id="tpBrokerageAmount"
            name="tpBrokerageAmount"
            className="brokerageDetailsInput"
            value={formData.tpBrokerageAmount}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="rewardOn" className="brokerageDetailsLabel">
            Reward On
          </label>
          <select
            id="rewardOn"
            name="rewardOn"
            className="brokerageDetailsSelect"
            value={formData.rewardOn}
            onChange={handleChange}
          >
            <option value="Brokerage">Brokerage</option>
            <option value="premium">Premium</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="rewardAmount" className="brokerageDetailsLabel">
            Reward Amount
          </label>
          <input
            type="number"
            id="rewardAmount"
            name="rewardAmount"
            className="brokerageDetailsInput"
            value={formData.rewardAmount}
            onChange={handleChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="rewardPercent" className="brokerageDetailsLabel">
            Reward %
          </label>
          <input
            type="number"
            id="rewardPercent"
            name="rewardPercent"
            className="brokerageDetailsInput"
            value={formData.rewardPercent}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="tpRewardPercent" className="brokerageDetailsLabel">
            TP Reward %
          </label>
          <input
            type="number"
            id="tpRewardPercent"
            name="tpRewardPercent"
            className="brokerageDetailsInput"
            value={formData.tpRewardPercent}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="tpAmount" className="brokerageDetailsLabel">
            TP Amount
          </label>
          <input
            type="number"
            id="tpAmount"
            name="tpAmount"
            className="brokerageDetailsInput"
            value={formData.tpAmount}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="exp" className="brokerageDetailsLabel">
            Exp
          </label>
          <input
            type="number"
            id="exp"
            name="exp"
            className="brokerageDetailsInput"
            value={formData.exp}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="expTP" className="brokerageDetailsLabel">
            Exp TP
          </label>
          <input
            type="number"
            id="expTP"
            name="expTP"
            className="brokerageDetailsInput"
            value={formData.expTP}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="refODPrem" className="brokerageDetailsLabel">
            Ref(OD Prem)
          </label>
          <input
            type="number"
            id="refODPrem"
            name="refODPrem"
            className="brokerageDetailsInput"
            value={formData.refODPrem}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="refTP" className="brokerageDetailsLabel">
            Ref (TP)
          </label>
          <input
            type="number"
            id="refTP"
            name="refTP"
            className="brokerageDetailsInput"
            value={formData.refTP}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="csc" className="brokerageDetailsLabel">
            CSC
          </label>
          <input
            type="number"
            id="csc"
            name="csc"
            className="brokerageDetailsInput"
            value={formData.csc}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="refPosAt" className="brokerageDetailsLabel">
            Ref/Pos At
          </label>
          <select
            id="refPosAt"
            name="refPosAt"
            className="brokerageDetailsSelect"
            value={formData.refPosAt}
            onChange={handleChange}
          >
            <option value="NP">NP</option>
            <option value="Ref">Ref</option>
          </select>
        </div>
      </div>

      <button
        type="button"
        className="calculate-rate-btn brokerageDetailsButton"
      >
        Get POS/MISP Rate
      </button>
    </div>
  );
};

export default BrokerageDetailsSection;
