import React, { useState } from 'react';
import axios from 'axios';
import './feadback.css';

const FeedbackForm = () => {
  const [rating, setRating] = useState(0);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    state: '',
    country: '',
    company: '',
    review: '',
    consent: false,
  });

  const [message, setMessage] = useState('');

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback`,
        {
          ...formData,
          rating,
        }
      );

      alert('Feedback submitted successfully!');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        city: '',
        state: '',
        country: '',
        company: '',
        review: '',
        consent: false,
      });
      setRating(0);
    } catch (error) {
      console.error('Error submitting feedback ---:', error);
      alert('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <div className="feedback-form-container">
      <div className="title-section">
        <h1>YOUR VOICE MATTERS! HELP US SERVE YOU BETTER.</h1>
        <p className='formPara'>
          At Simplinsure, your feedback is the cornerstone of our success. Share
          your experience and explore what others have to say about India's most
          trusted insurance service provider.
        </p>
      </div>

      <div className="form">
        <h2>SHARE YOUR EXPERIENCE</h2>
        <p>
          We value your feedback! Let us know how Simplinsure has helped you
          secure your future. Your reviews help us improve our services and
          allow potential customers to learn more about our offerings.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <input
              type="text"
              className="feedback-input"
              name="firstName"
              placeholder="*First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              className="feedback-input"
              placeholder="*Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="*E-mail ID"
              className="feedback-input"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phoneNumber"
              className="feedback-input"
              placeholder="*Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="city"
              className="feedback-input"
              placeholder="*City"
              value={formData.city}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="state"
              className="feedback-input"
              placeholder="*State"
              value={formData.state}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="country"
              className="feedback-input"
              placeholder="*Country"
              value={formData.country}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="company"
              className="feedback-input"
              placeholder="Company"
              value={formData.company}
              onChange={handleChange}
            />
          </div>

          <div className="rating">
            <span>*Rate Your Experience</span>
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= rating ? 'on' : 'off'}
                  onClick={() => handleRating(index)}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>

          <textarea
            name="review"
            className="feedback-text-area"
            placeholder="*Please share your experience with Simplinsure.com"
            value={formData.review}
            onChange={handleChange}
            required
          ></textarea>

          <div className="consent">
            <input
              type="checkbox"
              className="feedback-input"
              name="consent"
              id="consent"
              checked={formData.consent}
              onChange={handleChange}
              required
            />
            <label htmlFor="consent">
              By submitting your review, you consent to having your feedback
              displayed on Simplinsure.com. Your personal information will be
              handled securely and used solely for review purposes. You can opt
              out of future communications at any time. Simplinsure respects
              your privacy and complies with data protection laws.
            </label>
          </div>

          <button type="submit">SUBMIT</button>
        </form>

        {console.log('🚀 ~ FeedbackForm ~ message:', message)}
      </div>
    </div>
  );
};

export default FeedbackForm;

