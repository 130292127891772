import Footer from "../../../components/Footer/FooterNew"
import Img1 from '../../../assets/abous-us/directorCeo.png'
import Img2 from '../../../assets/abous-us/director.png'
import Img3 from '../../../assets/abous-us/director2.png'
import { useEffect } from "react"
import logo from '../../../assets/banner/1.png'
import { TbTargetArrow } from "react-icons/tb";
import { FaEye } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa6";
import './new-about.css'
import { useNavigate } from "react-router-dom";
import Quick from "../../../components/new_quick_links/quick"
import Hero from "../../../components/Hero_Banner/hero"
import { Helmet } from 'react-helmet';

export const AboutUs = () => {
  const navigate = useNavigate()
    useEffect(() => {
        localStorage.removeItem('no-header')
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        {/* SEO */}
         <Helmet>
  <meta name="title" content="Simpli Insure: Your Trusted Partner for the Right Insurance Solutions" />
  <meta name="description" content="Committed to ensuring your peace of mind, SimpliInsure.com delivers transparent, reliable, and tailored insurance solutions. Explore our trusted partnerships that strengthen our commitment to providing diverse and dependable insurance coverage." />
  <meta property="og:title" content="Simpli Insure: Your Trusted Partner for the Right Insurance Solutions" />
  <meta property="og:description" content="Committed to ensuring your peace of mind, SimpliInsure.com delivers transparent, reliable, and tailored insurance solutions. Explore our trusted partnerships that strengthen our commitment to providing diverse and dependable insurance coverage." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.SimpliInsure.com.com/about-simpliinsure" />
  <meta property="og:image" content="https://drive.google.com/file/d/117yZfnH8UaLQp8a8f8c-VcCEJrklJfQi/view?usp=sharing" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Simpli Insure: Your Trusted Partner for the Right Insurance Solutions" />
  <meta name="twitter:description" content="Committed to ensuring your peace of mind, SimpliInsure.com delivers transparent, reliable, and tailored insurance solutions. Explore our trusted partnerships that strengthen our commitment to providing diverse and dependable insurance coverage." />
  <meta name="twitter:image" content="https://drive.google.com/file/d/117yZfnH8UaLQp8a8f8c-VcCEJrklJfQi/view?usp=sharing" />
  </Helmet>

        {/* <Hero image={logo} btn = {false} /> */}
        <div className="mobileSpace"></div>
        <Quick heading={'About Us'}/>
        <div className="about_container">
          <div className="logo-data" >

            <p className="about-text">SIMPLI INSURE – <span className="about-text-span">BECAUSE LIFE SHOULD BE SIMPLE</span></p>

            <p className="about-sub-text">SimpliInsure.com, headquartered in Bangalore, is India's leading online insurance marketplace. Committed to revolutionizing the insurance landscape, our platform serves as a seamless bridge, simplifying the insurance procurement process for individuals, families, and enterprises. </p>

            <p className="about-sub-text">Simpliinsure.com, an online portal, is managed by Virtual Galaxy Insurance Brokers Pvt. Ltd., registered as a Direct Broker (Life & General) with Registration No. 750 and Registration Code IRDA/DB859/21.</p>
          </div>


          <div className="about_details">
          <div className="about_card">
            <FaEye className="about-icon" size={'2.3rem'} color="#800080"/>
            <p className='details-title'>OUR VISION</p>
            <p>Our vision is to provide suitable insurance solutions and simplify the acquisition process. By doing so, we have emerged as a beacon of trust and reliability, reshaping how insurance is discovered, compared, and purchased. We are committed to establishing a landscape where insurance is not just easily accessible but also transparent and tailored to individual and enterprise needs.</p> 
          </div>
          <div className="about_card">
            <TbTargetArrow className="about-icon" size={'2.3rem'}  color="#800080"/>
            <p className='details-title'>OUR MISSION</p>
            <p>
            We're dedicated to ensuring insurance is available and accessible to everyone. Committed to democratizing the insurance landscape, we tailor personalized solutions to meet each individual's specific requirements. Our platform empowers informed decision-making, providing clear and user-friendly choices
            </p>
          </div>
          <div className="about_card">
            <FaRegHandshake className="about-icon" size={'2.3rem'}  color="#800080"/>
            <p className='details-title'>COMMITMENT</p>
            <p>We prioritize offering accurate, reliable, and transparent information, empowering users to make informed decisions. Our user-centric platform ensures simplicity from exploration to policy purchase, guaranteeing an effortless experience. Security is paramount; we employ robust measures to safeguard user information and prioritize their privacy.</p>
          </div>
          </div>

          <div className="details_offer_section">
            <p className="what_we_offer">WHAT WE OFFER</p>
            <p className="spaceBottom">We offer a range of comprehensive insurance products such as Motor, Health, and Life Insurance, as well as various Business and Corporate insurance policies across all categories.</p> 

            <p className="spaceBottom">Our platform is the epitome of simplicity, designed to streamline the insurance process. For Individuals, we offer a spectrum of insurance options covering Health, Life, Motor, and Property Insurance. Whether it's Individual Health Insurance, Life Investment-Related Cover, or Car Insurance Policy, we've got you covered.</p>

            <p className="spaceBottom">For Corporate needs, we extend our expertise in Employee Welfare, Group Term, Mediclaim, Personal Accident, and more. Additionally, we safeguard assets through comprehensive covers like Burglary, Fire, Marine Cargo, and Machinery Breakdown Insurance.</p>
          </div>

        <div>
           <p className="management_team_title"> THE MANAGEMENT</p>
          <div className="management_team">
            <div onClick={() => navigate('/about/prashant-nimgade')} className="pointer">
              <img src={Img1} alt="director" className="new-director-img" />
              <p className="about-director-name">Prashant N Nimgade </p>
              <p className="about-director-designation">Director & CEO</p>
            </div>
            <div onClick={() => navigate('/about/avinash-shende')} className="pointer">
              <img src={Img2} alt="director" className="new-director-img" />
              <p className="about-director-name">Avinash Shende</p>
              <p className="about-director-designation">Director</p>
            </div>
            <div onClick={() => navigate('/about/sachin-pande')} className="pointer">
              <img src={Img3} alt="director" className="new-director-img" />
              <p className="about-director-name">Sachin Pande</p>
              <p className="about-director-designation">Director</p>
            </div>
            </div>
          </div>

        </div>
      <Footer />
        </>
    )
}