import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
// const combinedRedux = useSelector((state) => state.posRedux.posData);
import { updateAddress, updateFetchedPosInfo } from "../../../../src/Redux/posRedux";
import { updateProfile } from "../../../../src/Redux/AuthRedux";
import { useDispatch, useSelector } from "react-redux";
import { axiosPOSTCall, axiosGETCall1, axiosPOSTCall1 } from "../../../utilities/utils/apiPost";
import "./commonPos.css";
import { validation } from "../../../utilities/validations";
import ErrorPopups from "./ErrorPopups";

// import { HDiv } from "./styles"

// const ErrorPopups = error => {
//   return (
//     <div className='error-container-pos'>
//       <div className="error-msg-container-pos">{error.error}</div>
//       <div className="errArrow-pos" />
//       <img src={ErrorIconImg} className="error-icon-pos" alt="" />
//     </div>
//   )
// }

const Address = ({id}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  const dispatch = useDispatch();
  const [items1, setItems1] = useState(true);
  const posRedux = useSelector((state) => state.posRedux.posData);
  // const { userDetails } = useSelector((state) => state.auth);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [error, setError] = useState('')
  // console.log(" posRedux---> ", posRedux);
  const [posData, setPosData] = useState({
    name: '',
    houseNo: '',
    street: '',
    town: '',
    district: '',
    state: '',
    pinCode: '',
    area: '',
    seniorRM: '',
    seniorRmMob: '',
  });
  const [dataFetched, setDataFetched] = useState(false);

  const navigate = useNavigate();
  const fetchData = useCallback(
       (fetchId) => {
         axiosGETCall1(
           `/pospInfo/getPosInfo`,
           { id: fetchId },
           (callBack) => {
             if (callBack.status === 'Success') {
               if (userDetails.roles[0] !== 'admin') {
                 dispatch(
                   updateProfile({
                     userId: callBack.data.userId || userDetails.userId,
                     posId: callBack.data._id || fetchId,
                   })
                 );
               }
               dispatch(updateFetchedPosInfo(callBack.data || {}));
               setDataFetched(true);
             } else {
               setError('Failed to fetch data');
             }
           }
         );
       },
       [dispatch, userDetails.roles, userDetails.userId]
     );

   useEffect(() => {
     if (id) {
       fetchData(id);
     } else {
      fetchData();
     }
   }, [fetchData, id, navigate]);

   useEffect(() => {
     if (!userDetails) {
       navigate('/');
     } else if (dataFetched) {
       fetchData(id); 
     }
   }, [dataFetched, fetchData, navigate, userDetails, id]);


    useEffect(() => {
      if (userDetails?.roles && userDetails.roles[0] !== 'admin') {
        setPosData((prev) => ({
          ...prev,
          name: posRedux?.fullName || userDetails?.fullName,
          houseNo: posRedux?.houseNo || '',
          street: posRedux?.street || '',
          town: posRedux?.town || '',
          district: posRedux?.district || '',
          state: posRedux?.state || '',
          pinCode: posRedux?.pincode || '',
          area: posRedux?.area || '',
          seniorRM: posRedux?.informationRM?.name || '',
          seniorRmMob: posRedux?.informationRM?.phone || '',
        }));
      }
    }, [dataFetched]);

        // const clickHandle = async () => {

        //   let obj = {
        //     userId: userDetails.userId,
        //     fullName: posData.name,
        //     houseNo: posData.houseNo,
        //     street: posData.street,
        //     town: posData.town,
        //     district: posData.district,
        //     state: posData.state,
        //     pincode: posData.pinCode,
        //     area: posData.area,
        //     informationRM: {
        //       name: posData.seniorRM,
        //       phone: posData.seniorRmMob,
        //     },
        //   };
        //   if (!validation(posData.name, 'name').status) {
        //     setError({ name: 'Please Enter Full Name.' })
        //   } else if (!validation(posData.houseNo, 'hNo').status) {
        //     setError({ houseNo: 'Please Enter Valid House No.' })
        //   } else if (!validation(posData.street, 'alpha').status) {
        //     setError({ street: 'Please Enter Valid Street.' })
        //   } else if (!validation(posData.town, 'name').status) {
        //     setError({ town: 'Please Enter Valid Town.' })
        //   } else if (!validation(posData.district, 'name').status) {
        //     setError({ district: 'Please Enter Valid District.' })
        //   } else if (!posData.state) {
        //     setError({ state: 'Please Select State' })
        //   } else if (!validation(posData.pinCode, 'pinCode').status) {
        //     setError({ pinCode: 'Please Enter Pin Code' })
        //   } else if (!posData.area) {
        //     setError({ area: 'Please Select Area' })
        //   }
        //   else if (posData.seniorRM && !validation(posData.seniorRM, 'name').status) {
        //     setError({ seniorRM: 'Please Enter Full Name.' })
        //   } else if (posData.seniorRmMob && !validation(posData.seniorRmMob, 'mob').status) {
        //     setError({ seniorRmMob: 'Please Enter Senior RM Mobile Number.' })
        //   }
        //   else {
        //     axiosPOSTCall1("/pospInfo", obj, (callBack) => {
        //       console.log("🚀 ~ axiosPOSTCall1 ~ callBack:", callBack.data)
        //       if (callBack.status == "Success") {
        //         dispatch(
        //           updateProfile({
        //             userId: userDetails.userId,
        //             posId: callBack.data._id,
        //           })
        //         );
        //         dispatch(updateAddress(callBack.data));
        //         navigate("/details");
        //       }
        //     });
        //     setItems1(true);
        //   }
        // };

        // New logic for clickHandle
        const clickHandle = async () => {
        let errors = {};

        if (!validation(posData.name, 'name').status) {
          errors.name = 'Please Enter Full Name.';
        }
        if (!validation(posData.houseNo, 'hNo').status) {
          errors.houseNo = 'Please Enter Valid House No.';
        }
        if (!validation(posData.street, 'alpha').status) {
          errors.street = 'Please Enter Valid Street.';
        }
        if (!validation(posData.town, 'name').status) {
          errors.town = 'Please Enter Valid Town.';
        }
        if (!validation(posData.district, 'name').status) {
          errors.district = 'Please Enter Valid District.';
        }
        if (!posData.state) {
          errors.state = 'Please Select State';
        }
        if (!validation(posData.pinCode, 'pinCode').status) {
          errors.pinCode = 'Please Enter Pin Code';
        }
        if (!posData.area) {
          errors.area = 'Please Select Area';
        }
        if (posData.seniorRM && !validation(posData.seniorRM, 'name').status) {
          errors.seniorRM = 'Please Enter Full Name.';
        }
        if (posData.seniorRmMob && !validation(posData.seniorRmMob, 'mob').status) {
          errors.seniorRmMob = 'Please Enter Senior RM Mobile Number.';
        }

        if (Object.keys(errors).length > 0) {
          setError(errors);
        } else {
          try {
            let obj = {
              userId: userDetails.userId,
              fullName: posData.name,
              houseNo: posData.houseNo,
              street: posData.street,
              town: posData.town,
              district: posData.district,
              state: posData.state,
              pincode: posData.pinCode,
              area: posData.area,
              informationRM: {
                name: posData.seniorRM,
                phone: posData.seniorRmMob,
              },
            };

                axiosPOSTCall1("/pospInfo", obj, (callBack) => {
                  if (callBack.status === "Success") {
                    dispatch(
                      updateProfile({
                        userId: userDetails.userId,
                        posId: callBack.data._id || id,
                      })
                    );
                    dispatch(updateAddress(callBack.data));
                    navigate("/details");
                    setItems1(true);
                  } else {
                    console.error("Error:", callBack.message);
                    // Handle error scenario accordingly
                  }
                });

          } catch (error) {
            console.error("Error for Address POS:", error);
          }
        }
      };



      useEffect(() => {
        if (Object.keys(posRedux).length <= 0) {
          // navigate('/partner');
        } else if (
          !posRedux.gender ||
          !posRedux.email ||
          !posRedux.panNo ||
          !posRedux.qualification ||
          !posRedux.phone
        ) {
          navigate('/details');
        } else if (!posRedux?.language?.training || !posRedux?.language?.exam) {
          navigate('/lang');
        } else if (!posRedux?.segment) {
          navigate('/sengment');
        } else if (
          !posRedux?.documents?.profile ||
          !posRedux?.documents?.aadharFront ||
          !posRedux?.documents?.pancard ||
          !posRedux?.documents?.education ||
          !posRedux?.documents?.signature
        ) {
          navigate('/documents');
        }
      }, [navigate, posRedux]);

      const removeError = (key) => {
        switch (key) {
          case 'name':
            if (error.name) return setError(error.name = "")
            break
          case 'houseNo':
            if (error.houseNo) return setError(error.houseNo = "")
            break
          case 'street':
            if (error.street) return setError(error.street = "")
            break
          case 'district':
            if (error.district) return setError(error.district = "")
            break
          case 'town':
            if (error.town) return setError(error.town = "")
            break
          case 'state':
            if (error.state) return setError(error.state = "")
            break
          case 'pinCode':
            if (error.pinCode) return setError(error.pinCode = "")
            break
          case 'area':
            if (error.area) return setError(error.area = "")
            break
          case 'seniorRM':
            if (error.seniorRM) return setError(error.seniorRM = "")
            break
          case 'seniorRmMob':
            if (error.seniorRmMob) return setError(error.seniorRmMob = "")
            break
          default:
            break;
        }
      }


  useEffect(() => {
    var Authorization = localStorage.getItem('userDetails');
    if (!Authorization) {
      navigate('/signin-signup')
    }
  }, [])

  const cancelHandler = () => {
      const fromAdminDash = localStorage.getItem('addPos')

      if (fromAdminDash) {
        navigate('/dashboard/agent')
        localStorage.removeItem('addPos')
      } else {
      navigate('/work-with-us')
    }
  }


  return (
    <>
      <div className="mainWrapper">
        <div className="containerContent">
         <div className="cancelBtn" onClick={cancelHandler}>&times;</div>
          <Sidebar items1={items1} setItems1={setItems1} />

          <div className="pos_input_container">
            <h3>Address & Name :</h3>
            <form action="">
              <div className="input_box_divider">

                <div className=" input_box" onClick={() => removeError("name")}>
                  <p>FULL NAME&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    className="inputItem"
                    value={posData.name}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isAlphabets = /^[a-zA-Z\s]*$/.test(inputValue);
                      if (isAlphabets) {
                        removeError("name")
                        setPosData((prev) => ({ ...prev, name: e.target.value }))
                      } else {
                        setError({ name: "Enter Valid Name" })
                      }
                    }}
                  />
                  {error.hasOwnProperty('name') ? (
                    <ErrorPopups error={error.name} />
                  ) : null}
                </div>

                <div className=" input_box" onClick={() => removeError("street")}>
                  <p>STREET&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id="house"
                    name="street "
                    value={posData.street}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isAlphaNumeric = /^[a-zA-Z0-9\s]*$/.test(inputValue); // Regular expression to allow only alphanumeric characters
                      if (isAlphaNumeric) {
                        removeError("street")
                        setPosData((prev) => ({
                          ...prev,
                          street: e.target.value,
                        }))
                      } else {
                        setError({ street: "Enter valid AlphaNum character" })
                      }
                    }}
                  />
                  {error.hasOwnProperty('street') ? (
                    <ErrorPopups error={error.street} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("district")}>
                  <p>DISTRICT&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id="district"
                    name="district"
                    value={posData.district}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isAlphaNumeric = /^[a-zA-Z\s]*$/.test(inputValue); // Regular expression to allow only alphanumeric characters
                      if (isAlphaNumeric) {
                        removeError("district")
                        setPosData((prev) => ({
                          ...prev,
                          district: e.target.value,
                        }))
                      } else {
                        setError({ district: "Enter only valid character" })
                      }
                    }}
                  />
                  {error.hasOwnProperty('district') ? (
                    <ErrorPopups error={error.district} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("pinCode")}>
                  <p>PIN CODE&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id="district"
                    name="district"
                    maxLength={6}
                    value={posData.pinCode}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isValidInput = /^[0-9]*$/.test(inputValue); // Regular expression to allow only numbers
                      if (isValidInput) {
                        removeError("pinCode")
                        setPosData((prev) => ({
                          ...prev,
                          pinCode: inputValue,
                        }));
                      } else {
                        setError({ pinCode: "Enter only numerical values" })
                      }
                    }
                    }
                  />
                  {error.hasOwnProperty('pinCode') ? (
                    <ErrorPopups error={error.pinCode} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("seniorRM")} >
                  <p>SENIOR RM NAME</p>
                  <input
                    type="text"
                    id="rm"
                    name=" rm"
                    value={posData.seniorRM}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isAlphabets = /^[a-zA-Z\s]*$/.test(inputValue);
                      if (isAlphabets) {
                        removeError("seniorRM")
                        setPosData((prev) => ({
                          ...prev,
                          seniorRM: e.target.value,
                        }))
                      } else {
                        setError({ seniorRM: "Enter only AlphaNum values" })
                      }
                    }}
                  />
                  {error.hasOwnProperty('seniorRM') ? (
                    <ErrorPopups error={error.seniorRM} />
                  ) : null}
                </div>
              </div>

              <div className="input_box_divider">
                <div className="input_box" onClick={() => removeError("houseNo")}>
                  <p>HOUSE NUMBER&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id="number"
                    value={posData.houseNo}
                    name="num"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isAlphaNumeric = /^$|^[a-zA-Z0-9][a-zA-Z0-9\-_ ]*$/.test(inputValue); // Regular expression to allow only alphanumeric characters
                      if (isAlphaNumeric) {
                        removeError("houseNo")
                        setPosData((prev) => ({
                          ...prev,
                          houseNo: e.target.value,
                        }))
                      } else {
                        setError({ houseNo: "Enter only AlphaNum values" })
                      }
                    }
                    }
                  />
                  {error.hasOwnProperty('houseNo') ? (
                    <ErrorPopups error={error.houseNo} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("town")}>
                  <p>TOWN&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <input
                    type="text"
                    id="number"
                    name="num"
                    value={posData.town}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isAlphaNumeric = /^[a-zA-Z0-9\s]*$/.test(inputValue); // Regular expression to allow only alphanumeric characters
                      if (isAlphaNumeric) {
                        removeError("town")
                        setPosData((prev) => ({ ...prev, town: e.target.value }))
                      } else {
                        setError({ town: "Enter only AlphaNum values" })
                      }
                    }}
                  />
                  {error.hasOwnProperty('town') ? (
                    <ErrorPopups error={error.town} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("state")}>
                  <p>STATE&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <select
                    id="cars"
                    name="cars"
                    value = {posData.state}
                    onChange={(e) =>
                      setPosData((prev) => ({ ...prev, state: e.target.value }))
                    }
                  >
                    <option value={-1}>Select State :</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujrat">Gujrat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Mahararshtra">Mahararshtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="west bengel">west bengel</option>
                  </select>
                  {error.hasOwnProperty('state') ? (
                    <ErrorPopups error={error.state} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("area")}>
                  <p>AREA&nbsp;<span style={{ color: 'red', fontSize: '16px', position: 'absolute', top: '2px' }}>*</span></p>
                  <select
                    id="cars"
                    name="cars"
                    value={posData.area}
                    onChange={(e) =>
                      setPosData((prev) => ({ ...prev, area: e.target.value }))
                    }
                  >
                    <option value={-1}>Select Area :</option>
                    <option value="Urban">Urban</option>
                    <option value="Rural">Rural</option>
                  </select>
                  {error.hasOwnProperty('area') ? (
                    <ErrorPopups error={error.area} />
                  ) : null}
                </div>

                <div className="input_box" onClick={() => removeError("seniorRmMob")} >
                  <p>SENIOR RM MOBILE NUMBER </p>
                  <div className="mob_num_container" style={{ alignItems: 'center', position: 'absolute' }}>
                    <label className="num_mob">+91</label>
                    <input
                      type="text"
                      id="number"
                      name="num"
                      className="mob_num_input"
                      value={posData.seniorRmMob}
                      maxLength={10}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const isValidInput = /^[0-9]*$/.test(inputValue); // Regular expression to allow only numbers
                        if (isValidInput) {
                          removeError("seniorRmMob")
                          const formattedValue = /^[6-9]/.test(inputValue) ? inputValue : "";
                          if (!formattedValue) setError({ seniorRmMob: 'Number must start from 6' })
                          setPosData((prev) => ({
                            ...prev,
                            seniorRmMob: formattedValue,
                          }))
                        } else {
                          setError({ seniorRmMob: "Enter only Numbers" })
                        }
                      }}
                    />
                  </div>
                  {error.hasOwnProperty('seniorRmMob') ? (
                    <ErrorPopups error={error.seniorRmMob} />
                  ) : null}
                </div>
              </div>
            </form>

            <div className="btn_register">
              <button step={1} onClick={clickHandle}>
                NEXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
